<template>
  <v-container :fluid="showBillboardImage" class="pt-0 pb-0">
    <v-row>
      <v-col :class="mainWrapperClasses">
        <main class="mb-12 mt-8 pt-7">
          <router-view />
        </main>
        <footer>
          <div class="limited-content">
            <v-row class="legal-notice">
              <v-col cols="7">
                <p>
                  &copy;{{ new Date().getFullYear() }} DP IoT. All Rights Reserved.
                </p>
              </v-col>
              <v-col cols="5" class="text-right">
                <LocaleSwitcher />
              </v-col>
            </v-row>
          </div>
        </footer>
      </v-col>
      <v-col v-if="showBillboardImage" lg="8" class="billboard__wrapper">
        <div
          class="billboard__image"
          :style="{ backgroundImage: 'url(' + require('@/assets/' + billboardImage) + ')' }"
        />
        <div v-if="showBillboardText" class="billboard__message">
          <h2>{{ $t(billboardInfo.headline) }}</h2>
          <p>{{ $t(billboardInfo.subheadline) }}</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import LocaleSwitcher from '@/components/LocaleSwitcher';

export default {
  name: 'AnonymousView',
  components: {
    LocaleSwitcher,
  },
  beforeRouteUpdate(to, from, next) {
    this.billboardInfo = to.meta.billboard || {};
    next();
  },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      billboardInfo: null,
    };
  },
  computed: {
    billboardImage() {
      const { image } = this.billboardInfo;

      return image;
    },
    showBillboardImage() {
      if (typeof this.billboardImage === 'undefined') {
        return false;
      }

      return this.$vuetify.breakpoint.lgAndUp;
    },
    showBillboardText() {
      const { headline, subheadline } = this.billboardInfo;

      return typeof headline !== 'undefined' || typeof headline !== 'undefined' || subheadline;
    },
    mainWrapperClasses() {
      return {
        'col-12': true,
        'col-lg-4': this.showBillboardText,
      };
    },
  },
  created() {
    this.billboardInfo = this.$route.meta.billboard || {};
  },
};
</script>

<style lang="scss" scoped>
  @import './src/styles/variables';

  // component
  .signIn {
    &__logo {
      max-width: 100%;
      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        max-width: 240px;
      }
    }
  }

  .limited-content {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .legal-notice {
    font-size: 0.8125rem;
    color: lighten($text-color, 20%);
  }

  .billboard {
    &__wrapper {
      position: fixed;
      right: 0;
      min-height: auto;
      height: 100vh;
    }
    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $body-bg;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &__message {
      position: absolute;
      right: 5%;
      left: 5%;
      bottom: 15%;
      padding-top: 0;
      margin: auto;
      text-align: center;
      max-width: 480px;
      color: $body-bg;
      font-weight: 500;
      font-size: 120%;
    }
  }
</style>
