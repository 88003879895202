import gql from 'graphql-tag';
import { app } from '@/integration/firebase';
import { apolloClient } from '@/integration/apollo';

/**
 * @param {String} email
 * @returns {Promise<void>}
 */
export function resetPassword(email) {
  return app.auth().sendPasswordResetEmail(email);
}

/**
 * @param {String} code
 * @returns {Promise<string>}
 */
export function verifyResetCode(code) {
  return app.auth().verifyPasswordResetCode(code);
}
/**
 * @param {String} code
 * @param {String} password
 * @returns {Promise<void>}
 */
export function setNewPassword(code, password) {
  return app.auth().confirmPasswordReset(code, password);
}

export function setEmailConfirmed(email) {
  apolloClient.mutate({
    mutation: gql` mutation SetEmailConfirmed($email: String) {
      update: update_n3user(where: {email: {_eq: $email}, _and: {deleted_at: {_is_null: true}}}, _set: {email_confirmed: true}) {
        affected_rows
      }
    }`,
    variables: {
      email,
    },
  });
}
