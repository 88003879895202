<template>
  <div class="limited-content">
    <v-row justify="center">
      <v-col cols="6" class="text-center">
        <img src="@/assets/n3smart.png" alt="N3 Zones logo" class="signIn__logo">
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <p class="display-1 text--primary">
          {{ $t('login.verifyCode') }}
        </p>
        <v-alert
          v-if="error"
          text
          type="error"
          class="text-left mt-4"
        >
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" @submit.prevent="signIn">
      <v-row justify="center">
        <v-col cols="12">
          <v-text-field
            v-model="code"
            outlined
            :label="$t('form.label.code')"
            :rules="[rules.required, rules.phoneVerificationCode]"
          />
          <v-btn
            color="primary"
            block
            x-large
            :disabled="!valid"
            :loading="busy"
            @click="signIn"
          >
            {{ $t('action.logIn') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" align="center">
        <router-link :to="{ name: 'login.forgot' }" small="true">
          {{ $t('login.forgotPassword') }}
        </router-link>
      </v-col>
    </v-row>
    <div v-if="!recaptchaError" id="recaptcha-container" />
  </div>
</template>

<script>
import { required, phoneVerificationCode } from '@/validation/rules';

export default {
  name: 'CodeVerification',
  inject: ['authGuard'],
  props: {
    phoneInfoOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      valid: false,
      code: '',
      busy: false,
      error: null,
      verificationIdentifier: '',
      recaptchaError: false,
      rules: {
        required,
        phoneVerificationCode,
      },
    };
  },
  async mounted() {
    if (!this.phoneInfoOptions && !this.authGuard.currentUser().multiFactor) {
      this.$router.push({ name: 'login.signIn' });
    } else {
      this.verificationIdentifier = await this.authGuard.sendVerificationCode(this.phoneInfoOptions);
    }
  },
  methods: {
    signIn() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const { redirectTo } = this.$route.query;
      this.busy = true;

      this.authGuard
        .verifyCode(this.verificationIdentifier, this.code)
        .then(() => {
          if (redirectTo) {
            this.$router.push(redirectTo);
          } else {
            this.$router.push({ name: 'dashboard', params: { category: 'zones' } });
          }
        })
        .catch((err) => {
          this.busy = false;
          // eslint-disable-next-line
          /**
           * @todo re-validate what error should be shown and translate error messages
           * Possible errors:
           *  - https://firebase.google.com/docs/reference/js/firebase.auth.Error
           *  - https://firebase.google.com/docs/reference/js/firebase.auth.Auth#error-codes_8
          **/
          setTimeout(() => {
            this.recaptchaError = false;
          }, 1000);
          this.error = err.message;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import './src/styles/variables';

  .signIn {
    &__logo {
      vertical-align: middle;
      text-align: center;
      max-width: 100%;
      margin-bottom: 24px;
      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        max-width: 264px;
        margin-bottom: 32px;
      }
    }
  }

  .limited-content {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .billboard {
    &__wrapper {
      position: fixed;
      right: 0;
      min-height: auto;
      height: 100vh;
    }
    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $body-bg;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &__message {
      position: absolute;
      right: 5%;
      left: 5%;
      bottom: 15%;
      padding-top: 0;
      margin: auto;
      text-align: center;
      max-width: 480px;
      color: $body-bg;
      font-weight: 500;
      font-size: 120%;
    }
  }
</style>
