<template>
  <div class="limited-content">
    <v-row>
      <v-col cols="12" align="center">
        <img
          srcset="@/assets/n3smart.png 120w, @/assets/n3smart.png 389w"
          sizes="(max-width: 600px) 60px, 180px"
          src="@/assets/n3smart.png"
          alt="N3 Zones logo"
          class="mb-4 signIn__logo"
        >
        <v-alert
          v-if="errors.length"
          text
          color="red"
          class="text-left"
        >
          <span>{{ errors[0] }}</span>
        </v-alert>
        <div v-if="showConfirmation" class="text-left">
          <p class="display-1 text--primary">
            {{ $t('forgotPassword.headlineSuccess') }}
          </p>
          <v-alert
            text
            type="success"
            prominent
          >
            <strong class="text-capitalize">{{ $t('common.success') }}!</strong>
            <br>
            {{ $t('forgotPassword.subheadlineSuccess', { email: emailScrambled }) }}
          </v-alert>
          <p class="subtitle-1">
            {{ $t('forgotPassword.didNotReceiveInfo') }}
          </p>
          <v-btn
            color="primary"
            large
            :to="{ name: 'login.signIn' }"
          >
            {{ $t('forgotPassword.backToLogin') }}
          </v-btn>
        </div>
        <div v-else>
          <p class="display-1 text--primary">
            {{ $t('forgotPassword.headline') }}
          </p>
          <p class="text--primary text-left">
            {{ $t('forgotPassword.subheadline') }}
          </p>
        </div>
      </v-col>
    </v-row>
    <v-form
      v-if="!showConfirmation"
      ref="form"
      v-model="valid"
      @submit.prevent="sendEmail"
    >
      <v-row justify="center">
        <v-col cols="12">
          <v-text-field
            v-model="email"
            type="email"
            outlined
            :label="$t('form.label.email')"
            :rules="[rules.required, rules.email]"
          />
          <v-btn
            color="primary"
            type="submit"
            class="mr-5"
            :loading="busy"
            block
            large
          >
            {{ $t('forgotPassword.resetPassword') }}
          </v-btn>
          <router-link
            :to="{ name: 'login.signIn' }"
            class="my-4 d-block text-center"
            small
          >
            {{ $t('forgotPassword.backToLogin') }}
          </router-link>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>

import { required, email } from '@/validation/rules';
import { resetPassword } from '@/data/user';
import { scrambleEmail } from '@/helpers/string';

export default {
  name: 'LoginForgotPassword',
  data() {
    return {
      valid: false,
      busy: false,
      showConfirmation: false,
      errors: [],
      email: '',
      rules: {
        required,
        email,
      },
    };
  },
  computed: {
    emailScrambled() {
      return scrambleEmail(this.email);
    },
  },
  methods: {
    sendEmail() {
      this.errors = [];
      if (!this.$refs.form.validate()) {
        return;
      }

      this.busy = true;
      // remind password methods

      resetPassword(this.email)
        .then(() => {
          this.showConfirmation = true;
        })
        .catch(() => {
          this.errors = [
            this.$t('forgotPassword.error.unknownUser'),
          ];
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
};
</script>
