<template>
  <div class="limited-content">
    <v-row justify="center">
      <v-col cols="6" class="text-center">
        <img src="@/assets/n3smart.png" alt="N3 Zones logo" class="signIn__logo">
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-alert
          v-if="success"
          text
          type="success"
          class="text-left"
        >
          {{ $t('setNewPassword.successMessage') }}
        </v-alert>
        <p class="display-1 text--primary">
          {{ $t('login.headline') }}
        </p>
        <v-alert
          v-if="error"
          text
          type="error"
          class="text-left mt-4"
        >
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid">
      <v-row justify="center">
        <v-col cols="12">
          <v-text-field
            v-model="email"
            type="email"
            outlined
            :label="$t('form.label.email')"
            :rules="[rules.required, rules.email]"
          />
          <v-text-field
            v-model="password"
            outlined
            :label="$t('form.label.password')"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @keyup.enter="signIn"
          />
          <v-btn
            color="primary"
            block
            x-large
            :loading="busy"
            @click="signIn"
          >
            {{ $t('action.logIn') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" align="center">
        <router-link :to="{ name: 'login.forgot' }" small="true">
          {{ $t('login.forgotPassword') }}
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, email } from '@/validation/rules';
import { setEmailConfirmed } from '@/data/user';

export default {
  name: 'LoginForm',
  inject: ['authGuard'],
  props: {
    success: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      busy: false,
      showPassword: false,
      error: null,
      email: (process.env.NODE_ENV === 'development' ? 'dp-n3z-all@dpiot.no' : ''),
      password: (process.env.NODE_ENV === 'development' ? 'Note1234!' : ''),
      billboardImage: 'work_5.jpeg',
      rules: {
        required,
        email,
      },
    };
  },
  methods: {
    signIn() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const { redirectTo } = this.$route.query;
      this.busy = true;

      this.authGuard
        .authenticateAs(this.email, this.password)
        .then(() => {
          if (redirectTo) {
            this.$router.push(redirectTo);
          } else {
            this.$router.push({ name: 'dashboard', params: { category: 'zones' } });
          }
        })
        .then(() => {
          setEmailConfirmed(this.email);
        })
        .catch((err) => {
          this.busy = false;
          // eslint-disable-next-line
          /**
           * @todo re-validate what error should be shown and translate error messages
           * Possible errors:
           *  - https://firebase.google.com/docs/reference/js/firebase.auth.Error
           *  - https://firebase.google.com/docs/reference/js/firebase.auth.Auth#error-codes_8
          **/
          this.authGuard.addResolver(err.resolver);
          if (err.code === 'auth/multi-factor-auth-required') {
            this.authGuard.userDataChanged({ multiFactor: true, email: this.email });
            const phoneInfoOptions = {
              multiFactorHint: err.resolver.hints.find((e) => e.factorId === 'phone'),
              session: err.resolver.session,
            };
            this.$router.push({ name: 'login.verifyCode', query: { redirectTo }, params: { phoneInfoOptions } });
          }
          this.error = err.message;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import './src/styles/variables';

  .signIn {
    &__logo {
      vertical-align: middle;
      text-align: center;
      max-width: 100%;
      margin-bottom: 24px;
      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        max-width: 264px;
        margin-bottom: 32px;
      }
    }
  }

  .limited-content {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .legalNotice {
    font-size: 0.8125rem;
    color: lighten($text-color, 20%);
  }

  .billboard {
    &__wrapper {
      position: fixed;
      right: 0;
      min-height: auto;
      height: 100vh;
    }
    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $body-bg;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &__message {
      position: absolute;
      right: 5%;
      left: 5%;
      bottom: 15%;
      padding-top: 0;
      margin: auto;
      text-align: center;
      max-width: 480px;
      color: $body-bg;
      font-weight: 500;
      font-size: 120%;
    }
  }
</style>
