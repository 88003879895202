<template>
  <div class="limited-content">
    <v-row>
      <v-col cols="12" align="center">
        <img
          srcset="@/assets/n3smart.png 120w, @/assets/n3smart.png 389w"
          sizes="(max-width: 600px) 60px, 180px"
          src="@/assets/n3smart.png"
          alt="N3 Zones logo"
          class="mb-4 signIn__logo"
        >
        <v-skeleton-loader
          v-if="loading"
          class="mx-auto"
          max-width="500"
          type="article"
        />
        <div v-else>
          <v-alert
            v-if="errors.length"
            text
            color="red"
            class="text-left"
          >
            <span>{{ errors[0] }}</span>
          </v-alert>
          <div v-if="linkExpired" class="text-left">
            <v-alert
              text
              type="warning"
              prominent
            >
              <strong class="text-capitalize">{{ $t('common.warning') }}!</strong>
              <br>
              {{ $t('setNewPassword.linkExpired') }}
            </v-alert>
            <router-link
              :to="{ name: 'login.signIn' }"
              class="my-4 d-block text-center"
              small
            >
              {{ $t('setNewPassword.backToLogin') }}
            </router-link>
          </div>
          <div v-else>
            <p class="display-1 text--primary">
              {{ $t('setNewPassword.headline') }}
            </p>
            <p class="text--primary text-left">
              {{ $t('setNewPassword.subheadline', { email: emailScrambled }) }}
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-form
      v-if="!loading && !linkExpired"
      ref="form"
      v-model="valid"
      @submit.prevent="setPassword"
    >
      <v-row justify="center">
        <v-col cols="12">
          <v-text-field
            v-model="password"
            type="password"
            outlined
            :label="$t('form.label.password')"
            :rules="[rules.required, rules.password]"
          />
          <v-text-field
            v-model="rePassword"
            type="password"
            outlined
            :label="$t('form.label.passwordConfirm')"
            :rules="[rules.required, match, rules.password]"
          />
          <v-btn
            color="primary"
            type="submit"
            :loading="busy"
            block
            large
          >
            {{ $t('setNewPassword.setNewPasswordBtn') }}
          </v-btn>
          <router-link
            :to="{ name: 'login.signIn' }"
            class="my-4 d-block text-center"
            small
          >
            {{ $t('setNewPassword.backToLogin') }}
          </router-link>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>

import { password, required } from '@/validation/rules';
import { setEmailConfirmed, setNewPassword, verifyResetCode } from '@/data/user';
import { scrambleEmail } from '@/helpers/string';

export default {
  name: 'LoginSetPassword',
  data() {
    return {
      valid: false,
      busy: false,
      errors: [],
      password: '',
      rePassword: '',
      loading: true,
      linkExpired: false,
      code: '',
      email: '',
      rules: {
        required,
        password,
      },
    };
  },
  computed: {
    match() {
      return this.password === this.rePassword || this.$t('setNewPassword.error.passwordNotMatch');
    },
    emailScrambled() {
      return scrambleEmail(this.email);
    },
  },
  async mounted() {
    this.code = this.$route.query.oobCode;
    try {
      this.email = await verifyResetCode(this.code);
    } catch (e) {
      // available reasons @see https://firebase.google.com/docs/reference/js/firebase.auth.Auth#verifypasswordresetcode
      this.linkExpired = true;
    }
    this.loading = false;
  },
  methods: {
    setPassword() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.busy = true;
      setNewPassword(this.code, this.password)
        .then(() => {
          setEmailConfirmed(this.email);
        })
        .then(() => {
          this.$router.push({ name: 'login.signIn', params: { success: true } });
        })
        .catch(() => {
          this.busy = false;
          this.errors = [
            this.$t('forgotPassword.error.unknownUser'),
          ];
        });
    },
  },
};
</script>
